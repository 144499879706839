<template>
  <f7-app :params="f7params" color-theme="pink" theme-dark>
    <f7-view v-if="$store.state.loading" style="background-color:black;">
      <div class="add-padding text-align-center">
        <p id="demo-infinite-container"></p>
        <f7-preloader color="multi"></f7-preloader>
        <br />
        <br />
        <p class="welcome-msg">{{ welcome_message }}</p>
      </div>
    </f7-view>
    <f7-view v-else-if="$store.getters.iAmLoggedIn" theme-dark main style="background-color:black;">
      <f7-navbar :sliding="false" large theme-dark>
        <f7-nav-left>
          <f7-link icon-md="f7:bars" panel-open="left"></f7-link>
        </f7-nav-left>
        <f7-nav-title sliding>Chalet Facile</f7-nav-title>
        <f7-nav-title-large sliding>Chalet Facile</f7-nav-title-large>
      </f7-navbar>

      <f7-panel left cover ref="panel">
        <f7-view>
          <f7-page>
            <f7-navbar title="Chalet Facile"></f7-navbar>
            <f7-list>
              <f7-list-item
                v-if="$store.state.resa"
                title="Changer de chalet"
                @click="$store.state.resa=null; $refs.panel.close()"
              />
              <f7-list-item
                v-for="({ id, name, value }) in sideMenuInfos"
                :key="id"
                :title="name"
                target="_blank"
                :link="value"
                external
              />
              <f7-list-item title="Me déconnecter" @click="logout()"></f7-list-item>
            </f7-list>
          </f7-page>
        </f7-view>
      </f7-panel>

      <Index v-if="!$store.state.resa" :resas="$store.state.resas" />
      <Chalet v-else />
    </f7-view>

    <f7-login-screen v-else id="my-login-screen" :opened="$store.state.loginScreenOpened">
      <f7-view>
        <f7-page login-screen>
          <f7-login-screen-title>Bienvenue dans Chalet Facile</f7-login-screen-title>
          <f7-list>
            <f7-list-button
              :href="fbLoginUrl"
              title="Me connecter avec Facebook"
              color="blue"
              external
              outlined
              raised
              login-screen-close
            ></f7-list-button>
            <f7-block-footer></f7-block-footer>
          </f7-list>
          <br><br>
          <!-- <p style="text-align: center">Version: {{ version }}</p> -->
          <!-- <br />
          isLoggedIn ? {{ $store.getters.iAmLoggedIn }}
          <br />
          Token: {{ $store.state.token.slice(0,10) }}... -->
        </f7-page>
      </f7-view>
    </f7-login-screen>
  </f7-app>
</template>
<script>
import routes from "../js/routes.js";
import { getAccessTokenFromUrl } from "../js/getAccessTokenFromUrl.js";
import { getStorageToken, setStorageToken } from "../js/localStorage.js";
import Index from "../pages/index";
import Chalet from "../pages/chalet";

const SIDE_MENU_CATEGORY = 'menu'

const handleStorageToken = () => {
  const newToken = getAccessTokenFromUrl();
  if (newToken) setStorageToken(newToken);
  return getStorageToken();
};

export default {
  components: {
    Index,
    Chalet
  },
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: "Chalet Facile",
        theme: "md",
        lazy: {
          threshold: 200
        },
        routes
      }
    };
  },
  computed: {
    sideMenuInfos: ({ $store }) => $store.state.resa && $store.state.resa.infos.filter(({ category }) => category.name === SIDE_MENU_CATEGORY),
    version: () => process.env.VERSION,
    fbLoginUrl: ({}) =>
      `https://www.facebook.com/v5.0/dialog/oauth?client_id=811714152611320&redirect_uri=https://api.chaletfacile.com/_/custom/fb_callback_chaletfacile&state=${process.env.NODE_ENV}&scope=email`,
    welcome_message: ({ $store }) =>
      $store.state.me && `Ça s'en vient ${$store.state.me.surname}`
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("fetchMyInfo")
        .then(() => {
          // this.$store.dispatch("fetchAll");
          console.warn("conncted");
          this.$store.dispatch("fetchAllResas");
        })
        .catch(error => {
          console.warn("not connected", error.message);
          // this.$store.commit("showLoginScreen");
        });
    },
    runAnimationDemo() {
      this.showSwiperAnimation("#users li.media-item:nth-of-type(1)", "right");
      // this.showSwiperAnimation('#users li.media-item:nth-of-type(2)', 'left')
    },
    showSwiperAnimation(el, direction) {
      this.$f7.swipeout.open(el, direction, this.closeSwiperAnimation(el));
    },
    closeSwiperAnimation(el) {
      return () => {
        setTimeout(() => this.$f7.swipeout.close(el), 150);
      };
    },
    logout() {
      setStorageToken("");
      this.$store.commit("setToken", "");
      this.$store.commit("setMyInfo", "");
      this.$store.commit("showLoginScreen");
    }
  },
  created() {
    const token = handleStorageToken();

    if (token) {
      this.$store.commit("setToken", token);
      this.fetchData();
    } else {
      this.$store.commit("showLoginScreen");
    }
  },
  mounted() {
    this.$f7ready(f7 => {});
  }
};
</script>

<style scoped>
.block {
  margin: 0.5em 1.5em;
}

.add-padding {
  padding-top: 60vw;
}
</style>
