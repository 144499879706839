<template>
  <div>
    <f7-row class="display-flex justify-content-space-around">
      <f7-col width="100" medium="40" class>
        <f7-block-title>Participants</f7-block-title>
        <f7-list media-list>
          <f7-list-item
            v-for="{ id, name, messenger_username } in users"
            :key="id"
            :title="name"
            after="Contacter"
            text
            swipeout
            :link="'https://m.me/'+messenger_username"
            target="_blank"
            external
          >
            <!--           <f7-swipeout-actions left>
            <f7-swipeout-button :href="'tel:+1800229933'" target="_blank" class="external" color="green">Appeler</f7-swipeout-button>
          </f7-swipeout-actions>
            -->
            <img
              class="img-rounded"
              slot="media"
              src="https://banner2.cleanpng.com/20180725/fic/kisspng-computer-icons-user-profile-social-web-5b5877b5ef7d48.492420571532524469981.jpg"
              width="55"
            />
            <f7-swipeout-actions right>
              <f7-swipeout-button
                :href="'https://m.me/'+messenger_username"
                target="_blank"
                class="external"
                color="blue"
              >Messenger</f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
export default {
  computed: {
    users: ({ $store: { getters } }) => getters.users
  }
};
</script>
<style scoped>
.img-rounded {
  border-radius: 50%;
}
</style>