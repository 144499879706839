<template>
  <div v-if="$store.state.resa">
    <f7-block class="display-flex align-items-center" medium>
      <f7-icon
        ios="material:directions_car"
        aurora="material:directions_car"
        md="material:directions_car"
        color="green"
      ></f7-icon>&nbsp;Voitures à l'aller
    </f7-block>

    <f7-swiper
      class="swiper-init"
      :params="{slidesPerView: 1.5, spaceBetween: 10, centeredSlides: true}"
    >
      <f7-swiper-slide
        v-for="lift in $store.getters.lifts('departure')"
        :key="lift.id"
        style="width: 90%;"
      >
        <Voiture :lift="lift" :iAmInALift="$store.getters.iAmInALift('departure')"></Voiture>
      </f7-swiper-slide>
    </f7-swiper>

    <f7-block class="display-flex align-items-center" medium>
      <f7-icon
        ios="material:directions_car"
        aurora="material:directions_car"
        md="material:directions_car"
        color="red"
      ></f7-icon>&nbsp;Voitures au retour
    </f7-block>

    <f7-swiper
      class="swiper-init"
      :params="{slidesPerView: 1.5, spaceBetween: 10, centeredSlides: true}"
    >
      <f7-swiper-slide v-for="lift in $store.getters.lifts('return')" :key="lift.id">
        <Voiture :lift="lift" :iAmInALift="$store.getters.iAmInALift('return')"></Voiture>
      </f7-swiper-slide>
    </f7-swiper>
  </div>
</template>

<script>
import Voiture from "../components/voiture.vue";

export default {
  components: {
    Voiture
  },
  computed: {}
};
</script>
