<template>
  <f7-list media-list>
    <f7-list-item
      v-for="({ name, quantity, unit }) in ingredients"
      :key="name"
      :title="name"
      :after="displayQuantity(quantity, unit)"
      checkbox
    >
      <!-- footer="Pour menus: Brunch (x2), Tartiflette, Raclette, Pierrade" -->
    </f7-list-item>
  </f7-list>
</template>

<script>
export default {
  props: {
    ingredients: Array,
  },
  methods:{
    displayQuantity: (quantity, unit) => `${quantity.toFixed(2)} ${unit}`
  }
};
</script>

