<template>
  <div>
    <f7-block
      v-if="!$store.getters.allUsersHaveLifts"
      class="warning"
    >Attention, les quantités sont calculées en fonction des horaires des voitures, et les participants n'ont pas tous choisi leurs voitures.</f7-block>

    <f7-block-title>Les menus</f7-block-title>

    <f7-list accordion-list class="menus">
      <f7-list-item
        v-for="(day, index) in $store.getters.allMenuDays"
        :key="day"
        accordion-item
        :accordion-item-opened="index === 0"
        :title="formatDay(day)"
      >
        <f7-accordion-content>
          <Menu :menus="$store.getters.menusOfDay(day)"></Menu>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-block-title>Les courses</f7-block-title>

    <f7-list accordion-list class="menus">
      <f7-list-item
        v-for="(ingredients, category) in ingredientsByCategory"
        :key="category"
        accordion-item
        :title="category"
      >
        <f7-accordion-content>
          <Ingredients :ingredients="ingredients"></Ingredients>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import Menu from "../components/menu.vue";
import Ingredients from "../components/ingredients.vue";
import { formatDay } from "../js/moment.js";

import { groupBy } from "lodash-es";
import { groupAllMenusIngredients } from "../js/ingredients.js";

export default {
  components: {
    Menu,
    Ingredients
  },
  computed: {
    ingredientsByCategory: ({ $store }) => {
      const allIngredients = groupAllMenusIngredients($store.getters.menus);
      return groupBy(allIngredients, "category.name");
    }
  },
  methods: {
    formatDay
  }
};
</script>

<style scoped>
div.warning {
  color: darkorange;
  font-style: italic;
  font-size: 0.8em;
}
</style>
