<template>
  <f7-page style="padding-top:3em;">
    <f7-tabs>
      <f7-tab id="chalet" tab-active>
        <Home />
      </f7-tab>
      <f7-tab id="cars">
        <Cars />
      </f7-tab>
      <f7-tab id="courses">
        <Courses />
      </f7-tab>
      <f7-tab id="users">
        <Users />
      </f7-tab>
    </f7-tabs>

    <f7-toolbar tabbar labels bottom theme-dark>
      <f7-link tab-active tab-link="#chalet" tab-link-active icon-md="material:home" text="Chalet"></f7-link>
      <f7-link tab-link="#cars" icon-md="material:directions_car" text="Voitures"></f7-link>
      <f7-link tab-link="#courses" icon-md="material:local_dining" text="Menus"></f7-link>
      <f7-link tab-link="#users" icon-md="f7:person_3_fill" text="Participants"></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<script>
import Home from "../pages/home";
import Cars from "../pages/cars";
import Courses from "../pages/courses";
import Users from "../pages/users";

export default {
  components: {
    Home,
    Cars,
    Courses,
    Users
  },
  methods: {
    logout() {
      setStorageToken("");
      this.$store.commit("setToken", "");
      this.$store.commit("showLoginScreen");
    }
  },
  mounted() {}
};
</script>