import { groupBy } from 'lodash-es'
import moment from 'moment'

const sanitizeIngredientObj = ({ ingredient: { name = '', unit = '', category = '' }, quantity = 0 }) => ({ name, unit, category, quantity })

const getTotalIngredientQuantity = (array = [], name = '') => {
	const ingredient = array.find(isIngredient(name))
	return ingredient ? ingredient.quantity : 0
}

const isIngredient = ingredientName => ({ name }) => name === ingredientName
const isNotIngredient = ingredientName => ({ name }) => name !== ingredientName

const groupByName = (acc, { name, quantity, ...props }) => {
	const totalQuantity = getTotalIngredientQuantity(acc, name)
	const newIngredient = { ...props, name, quantity: totalQuantity + quantity }
	return acc.filter(isNotIngredient(name)).concat(newIngredient)
}

const getMenuIngredientsWithQuantity = users => ingredient => ({ 
	...ingredient, 
	quantity: ingredient.quantity * users.length 
})

export const groupAllMenusIngredients = menus =>{
	const result = menus
		.map(({ menu, users }) => menu.ingredients.map(getMenuIngredientsWithQuantity(users)))
		.flat()
		.map(sanitizeIngredientObj)
		.reduce(groupByName, [])

	return result
}

export const userIsInTheMenu = ({ datetime }) => ({ lifts }) => 
	lifts.length === 2 ? moment(datetime).isBetween(lifts[0].datetime, lifts[1].datetime) : false