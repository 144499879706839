<template>
  <f7-list media-list>
    <f7-list-item
      v-for="({ menu: { id, name, description, image }, datetime, users }, index) in menus"
      :key="id"
      link="#"
      :title="name"
      :after="users.length"
      :subtitle="getHumanTime(datetime)"
    >
      <img
        class="img-rounded"
        slot="media"
        :src="$store.getters.thumbnail(image && image.filename)"
        width="80"
      />
      <div class="warning" v-if="!myLiftDates.length">Vous n'avez pas choisi vos voitures</div>
      <div
        class="warning"
        v-else-if="!iAmInTheMenu(datetime)"
      >Vous ne serez pas au chalet à ce repas</div>
      <!-- <f7-badge color="bluec">Vege</f7-badge> -->
    </f7-list-item>
  </f7-list>
</template>

<script>
import moment from "moment";
import { getHumanTime } from "../js/moment.js";
import { groupAllMenusIngredients } from "../js/ingredients.js";

moment.locale("fr");

export default {
  props: {
    menus: Array
  },
  computed: {
    myLiftDates: ({ $store }) => $store.getters.myLiftDates
  },
  methods: {
    iAmInTheMenu(menuTime) {
      if (!this.myLiftDates) return true;
      return moment(menuTime).isBetween(...this.myLiftDates);
    },
    getHumanTime
  },
  mounted() {}
};
</script>

<style scoped>
.img-rounded {
  border-radius: 10%;
}
div.warning {
  color: darkorange;
  font-style: italic;
  font-size: 0.8em;
}
li >>> div.item-subtitle {
  font-style: italic;
  font-size: 0.8em;
}
li >>> div.item-after:after {
  content: " pers.";
}
</style>