<template>
  <div :class="{'car-selected': iAmInThisLift}">
    <f7-block-title class="text-align-center">La {{ lift && lift.driver.surname }}-Mobile</f7-block-title>

    <f7-card class="elevation-3">
      <f7-card-content :padding="false">
        <f7-list>
          <f7-list-item
            v-for="(seat, id) in seatsArray"
            :key="id"
            :title="seat.name"
            :class="seat.status"
          >
            <span class="driver">{{seat.isDriver ? 'Conducteur' : ``}}</span>
          </f7-list-item>
        </f7-list>
        <div class="button-container">
          <div class="button-add special-link">
            <f7-button v-if="loading" raised strong outline>
              <f7-preloader size="16" color="pink"></f7-preloader>
            </f7-button>
            <f7-button
              v-else-if="!iAmInALift && emptySeats.length"
              raised
              strong
              @click="toggleUserFromLift()"
              fill
            >M'ajouter</f7-button>
            <f7-button
              v-else-if="iAmInThisLift"
              raised
              strong
              @click="toggleUserFromLift()"
              outline
            >Me retirer</f7-button>
          </div>
        </div>
      </f7-card-content>
      <f7-card-footer v-if="date">
        <span style="color: darkorange;">
          <b style="color:red;">Départ:</b>
          <br />
          {{date}}
          <br />
          {{lift.location}}
        </span>
        <f7-button
          v-if="lift.location"
          target="_blank"
          class="external"
          :href="'https://www.google.com/maps/search/?api=1&query='+lift.location"
          outline
          round
          raised
          style="border-width: 1px;"
        >
          <f7-icon ios="material:room" aurora="material:room" md="material:room" color="pink"></f7-icon>
        </f7-button>
      </f7-card-footer>
      <f7-card-footer v-if="lift.comment">
        <span>
          <b style="color:red;">Message du conducteur:</b>
          {{lift.comment}}
        </span>
      </f7-card-footer>
    </f7-card>
  </div>
</template>

<script>
import routes from "../js/routes.js";
import { userIsInTheLift } from "../js/car.js";
import moment from "moment";
import ButtonLicorne from "./button-licorne.vue";
import { fireworks } from "../js/animations.js";

moment.locale("fr");

const createSeat = (
  name = "(Disponible)",
  isEmpty = true,
  isMe = false,
  isDriver = false
) => ({
  name,
  isMe,
  isDriver,
  status: isEmpty
    ? "seat-empty"
    : isMe
    ? "my-seat"
    : isDriver
    ? "driver-seat"
    : ""
});

export default {
  components: {
    ButtonLicorne
  },
  props: {
    iAmInALift: {
      type: Boolean,
      required: true
    },
    lift: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    me: ({ $store }) => $store.state.me,
    iAmInThisLift: ({ me, lift }) => userIsInTheLift(me)(lift),
    usersArray: ({ lift, me }) =>
      lift.users.map(({ user }) =>
        createSeat(
          user.surname,
          false,
          user.id === me.id,
          user.id === lift.driver.id
        )
      ) || [],
    seatsArray: ({ usersArray, emptySeats }) => [...usersArray, ...emptySeats],
    buttonClass: ({ iAmInThisLift, loading }) =>
      iAmInThisLift || loading ? "button-outline" : "button-fill",
    buttonText: ({ iAmInThisLift }) =>
      iAmInThisLift ? "Me retirer" : `M'ajouter`,
    emptySeats: ({ lift, usersArray }) =>
      Array(lift.places - usersArray.length)
        .fill()
        .map(v => createSeat()),
    date: ({ lift }) =>
      lift.datetime &&
      moment(lift.datetime, "YYYY-MM-DD HH:mm:ss").format("ddd DD MMM HH:mm")
  },
  methods: {
    // using async/await doesn't work because of some babel error :(
    toggleUserFromLift() {
      const {
        me,
        lift,
        $el,
        $store: { dispatch, state }
      } = this;

      this.loading = true; // component button loader
      const noLoading = true; // no global page loader

      const container = $el.querySelector(".button-container");
      const el = $el.querySelector(".button-add");

      if (this.iAmInThisLift) {
        dispatch("removeUserFromLift", {
          lift,
          user: me,
          noLoading
        })
          .then(r => dispatch("fetchResa", { id: state.resa.id, noLoading }))
          .then(this.stopButtonLoading);
      } else {
        dispatch("addUserToLift", {
          user: me.id,
          lift: lift.id,
          noLoading
        })
          .then(r => dispatch("fetchResa", { id: state.resa.id, noLoading }))
          .then(r => fireworks(el, container).map(anim => anim.replay()))
          .then(this.stopButtonLoading);
      }
    },
    stopButtonLoading() {
      this.loading = false;
    }
  },
  mounted() {}
};
</script>

<style scoped>
.seat-empty {
  font-style: italic;
  color: grey;
  font-size: 0.8em;
}
.card-footer {
  font-size: 0.8em;
}
.car-selected {
  border: 2px solid #ff0076;
  border-radius: 5em;
  box-shadow: inset 0px 0px 20px 0px darkred;
}

li.my-seat >>> .item-title {
  font-weight: 900;
  /* text-decoration: underline; */
}

.driver {
  font-size: 0.8em;
}
</style>