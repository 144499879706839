var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-list',{attrs:{"media-list":""}},_vm._l((_vm.menus),function(ref,index){
var ref_menu = ref.menu;
var id = ref_menu.id;
var name = ref_menu.name;
var description = ref_menu.description;
var image = ref_menu.image;
var datetime = ref.datetime;
var users = ref.users;
return _c('f7-list-item',{key:id,attrs:{"link":"#","title":name,"after":users.length,"subtitle":_vm.getHumanTime(datetime)}},[_c('img',{staticClass:"img-rounded",attrs:{"slot":"media","src":_vm.$store.getters.thumbnail(image && image.filename),"width":"80"},slot:"media"}),_vm._v(" "),(!_vm.myLiftDates.length)?_c('div',{staticClass:"warning"},[_vm._v("Vous n'avez pas choisi vos voitures")]):(!_vm.iAmInTheMenu(datetime))?_c('div',{staticClass:"warning"},[_vm._v("Vous ne serez pas au chalet à ce repas")]):_vm._e()])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }