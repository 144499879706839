<template>
  <div>
    <f7-block>
      <f7-row>
        <f7-col>
          Début
          <f7-block-footer>{{ formatDate($store.state.resa.date_start) }}</f7-block-footer>
        </f7-col>
        <f7-col class="text-align-right">
          Fin
          <f7-block-footer>{{ formatDate($store.state.resa.date_end) }}</f7-block-footer>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block class="text-align-right">
      {{ address }}
    </f7-block>


    <f7-swiper :params="{slidesPerView: 2, spaceBetween: 10, centeredSlides: true}">
      <f7-swiper-slide navigation v-for="(image, key) in $store.getters.getPhotosArray" :key="key">
        <div style="width: 100%;">
          <img
            @click="$refs.photoBrowser.open(key)"
            :src="image.url + '?aki_policy=small'"
            style="width: 100%; max-width: 300px; height: auto; border-radius: 10px;"
            class="lazy lazy-fade-in"
          />
        </div>
      </f7-swiper-slide>
    </f7-swiper>

    <f7-block>
      <f7-button
        :href="$store.state.resa.chalet.external_url"
        target="_blank"
        external
        outline
        raised
      >Voir le chalet</f7-button>
    </f7-block>


    <f7-list media-item>
      <f7-list-item v-for="({ id, name, value }) in mainPageInfos" :key="id" :title="name" :after="value"></f7-list-item>
      <f7-list-item
        v-if="wifi"
        title="Mot de passe WIFI"
        :after="wifi"
        @click="copyToClipboard(wifi)"
      >
        &nbsp;
        <f7-button v-if="!copiedToClipboard" class="wifi-btn" outline small>
          <small>Copier</small>
        </f7-button>
        <f7-button v-else class="wifi-btn" fill bg-color="red" small>
          <small>Copié !</small>
        </f7-button>
      </f7-list-item>

    </f7-list>



    <f7-photo-browser
      :photos="$store.getters.getPhotosArray"
      theme="dark"
      ref="photoBrowser"
      type="popup"
      routable-modals
      :view="$f7.views.current"
      navbar-of-text="/"
      swipe-to-close
    />
    <f7-block>
      <iframe
        class="googlemap"
        :src="$store.state.resa.chalet.map_iframe_src"
        frameborder="0"
        style="border:0;width:100%;"
        allowfullscreen
      ></iframe>
    </f7-block>
  </div>
</template>

<script>
import { state, dispatch, commit, actions } from "../store";
import { formatDate } from "@/js/moment";

const MAIN_PAGE_INFOS_CATEGORY = 'main_page'

export default {
  data: () => ({
    copiedToClipboard: false
  }),
  props: {
    id: Number
  },
  computed: {
    address: ({$store}) => $store.state.resa.chalet.city,
    wifi: ({ $store }) => $store.state.resa.chalet.wifi_password,
    chalet: ({ $store }) => $store.state.resa.chalet,
    photosArray: ({ chalet }) =>
      chalet && chalet.image_links.map(({ image_link = "", name = "" }) => ({
        url: image_link,
        caption: name
      })),
    //array of objets for photo-browser component
    mainPageInfos: ({ $store }) => $store.state.resa.infos.filter(({ category }) => category.name === MAIN_PAGE_INFOS_CATEGORY)
  },
  methods: {
    copyToClipboard(value) {
      this.$clipboard(value);
      this.copiedToClipboard = true;
      setTimeout(() => {
        this.copiedToClipboard = false;
      }, 3000);
    },
    formatDate
  },
  mounted() {
    // this.$f7.lazy.create(".page-content");
  }
};
</script>

<style scoped>
.menus >>> .item-title {
  text-transform: capitalize;
}

iframe.googlemap {
  /* box-shadow: 0px 0px 20px 0px darkgrey; */
  border-radius: 10px;
}
</style>