export const getAccessTokenFromUrl = () => {
  const hashtags = window.location.hash.substr(1).split('&').reduce((result, item) => {
    const parts = item.split('=');
    result[parts[0]] = parts[1];
    return result;
  }, {})

  window.history.replaceState({}, document.title, '/')

  // console.log('Facebook Login Error?', hashtags.fb_login_error);

  return hashtags.static_token || ''
}
