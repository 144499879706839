
import HomePage from '../components/app.vue';

import NotFoundPage from '../pages/404.vue';

var routes = [

  {
    path: '/',
    component: HomePage,
  },

  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes
