import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios'
// import { MyError } from '../src/js/MyError.js'
import { userIsInTheLift } from '../js/car.js'
import moment from 'moment'
import { userIsInTheMenu } from '../js/ingredients.js'
import { groupBy } from 'lodash-es'
import { getStorageToken, setStorageToken } from "../js/localStorage.js";

moment.locale('fr')

const apiDomain = `https://api.chaletfacile.com`

//test

// import store

// const storedispatch = ({ state, getters, dispatch, commit }) => ({ param }) => test

// //1
// const actions = {

// }

// //2

// //3
// export actions = {
// 	fetchApi: ({ ...params }) => dispatch(func.name(),{ ...params })
// }



// const storeActions = {
// 	actionName: ()
// }

// store.add(storeActions)

//endtest

const run = (commit, mutationName) => data => commit(mutationName, data)

const concatParams = ([name = '', value = '']) => `${name}=${value}`
const buildParamsString = (params = []) => params.map(concatParams).join('&')

const handleApiError = commit => error => {
	setStorageToken("");

	commit('showLoginScreen')
	commit('addToLoading', -1)
	commit('setToken', '')
	commit('setMyInfo', '')
	
	console.warn('error:',error.message)
	throw error
}

export const state = () => ({
	apiUrl: `${apiDomain}/_`,
	image: {
		original: `${apiDomain}/uploads/_/originals/`,
		standard: `${apiDomain}/thumbnail/_/600/600/contain/best/`,
		thumbnail: `${apiDomain}/thumbnail/_/300/300/crop/best/`,
	},
	resas: [],
	resa: null,
	me: null,
	token: '',
	loginScreenOpened: false,
	loading: 0
})


export const actions = {
	action: (store, { fn, payload }) => fn(store, payload),
	fetchAllResas({ state, commit }) {
		const fields = [
			'resa.id', 'resa.name', 'resa.chalet.*.*',
		].join(',')

		const params = [
			['fields', fields],
			['limit', '100'],
			['filter[user]', state.me.id],
			['access_token', state.token],
		]
		const url = `${state.apiUrl}/items/resa_user?${buildParamsString(params)}`
		return axios.get(url)
			.then(res => res.data.data)
			.then(run(commit, 'setResas'))
			.catch(handleApiError(commit))

	},
	fetchResa({ state, commit }, { id }) {
		if (!id) return
		const fields = [
			'id',
			'name',
			'chalet.*.*',
			'menus.menu.*.*.*.*',
			'menus.datetime',
			'menus.menu.image.filename',
			'menus.menu.ingredients.quantity',
			'menus.menu.ingredients.ingredient.*.*',
			'users.user.*',
			'lifts.*',
			'lifts.users.id',
			'lifts.users.user.*',
			'lifts.driver.*',
			'date_start',
			'date_end',
			'infos.*.*',
			// 'users.directus_user.*',
			'*'
		].join(',')

		const params = [
			['fields', fields],
			['limit', '1'],
			['access_token', state.token],
		]
		const url = `${state.apiUrl}/items/resa/${id}?${buildParamsString(params)}`
		console.log(url)
		return axios.get(url)
			.then(res => res.data.data)
			.then(run(commit, 'setGlobalState'))
			.catch(handleApiError(commit))
	},
	fetchMyInfo({ state, commit }) {
		const params = [
			['fields', '*.*.*'],
			['limit', '1'],
			['filter[directus_user.token]', state.token],
			['access_token', state.token],
		]
		const url = `${state.apiUrl}/items/user?${buildParamsString(params)}`
		return axios.get(url)
			.then(res => res.data.data[0])
			.then(run(commit, 'setMyInfo'))
			.catch(handleApiError(commit))
	},
	addUserToLift({ state, commit, dispatch }, { user, lift, loading = false }) {
		const url = `${state.apiUrl}/items/lift_user`
		const config = { headers: { 'Authorization': "Bearer " + state.token } }
		const body = { user, lift }
		return axios.post(url, body, config).catch(handleApiError(commit))
	},
	removeUserFromLift({ state, commit, dispatch }, { lift, user, loading = false }) {
		const junctionId = lift.users.find(u => u.user.id === user.id).id
		const url = `${state.apiUrl}/items/lift_user/${junctionId}`
		const config = { headers: { 'Authorization': "Bearer " + state.token } }

		return axios.delete(url, config).catch(handleApiError(commit))
	},
}

export const mutations = {
	addToLoading(state, nb = 0){
		state.loading += nb
	},
	setGlobalState(state, data) {
		this._vm.$f7.notification.create({
			title: 'Mutation',
			text: 'Mutation du state global',
			closeTimeout: 3000,
		});
		state.resa = data
		return data
	},
	setResas(state, data) {
		state.resas = data
		return data
	},
	setMyInfo: (state, data) => {
		state.me = data
	},
	setToken: (state, token) => {
		state.token = token
	},
	showLoginScreen: (state) => {
		state.loginScreenOpened = true
	}
}

// const groupByDay = (acc, { day, menu }) => ({ 
// 	...acc, 
// 	[day] : [
// 		...(acc[day] ? acc[day] : []),
// 		menu
// 	]
// })

const sortByDatetime = (a, b) => a.datetime > b.datetime ? 1 : -1

const addMenuDay = ({ datetime, menu }) => ({
	day: moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
	datetime,
	menu,
})

const filterLifts = (lifts = [], direction = '') => lifts.filter(l => l.direction === direction)

const sortMyLiftFirst = me => (lift1, lift2) => userIsInTheLift(me)(lift1) ? -10 : userIsInTheLift(me)(lift2) ? -10 : 0

const findUserLift = (direction, user_id) => lift => lift.user_id === user_id && lift.direction === direction

const getUserLifts = (allUsersLifts, user_id) => [
	allUsersLifts.find(findUserLift('departure', user_id)),
	allUsersLifts.find(findUserLift('return', user_id))
]
	.filter(u => u)
	.sort(sortByDatetime)

const addUserToMenu = menu => (acc, current) => userIsInTheMenu(menu)(current) ? [...acc, current] : acc

const addUsersToMenu = users => menu => ({ ...menu, users: users.reduce(addUserToMenu(menu), []) })

export const getters = {
	// allLifts: ({ resa }) => resa.voitures.map(car => car.lifts).flat(),
	users: ({ resa }, { allUsersLifts }) => resa.users.map(({ user }) => ({ ...user, lifts: getUserLifts(allUsersLifts, user.id) })),
	allUsersHaveLifts: (state, { users }) => users.every(({ lifts }) => lifts.length === 2),
	allUsersLifts: ({ resa }) => resa.lifts.map(({ users, direction, datetime }) => users.map(({ user }) => ({ user_id: user.id, direction, datetime }))).flat(),
	iAmLoggedIn: ({ me }) => !!me,
	// allLifts: ({ resa }) => resa.lifts,
	departureLifts: (state, { lifts }) => lifts('departure'),
	returnLifts: (state, { lifts }) => lifts('return'),
	lifts: ({ me, resa }) => direction => filterLifts(resa.lifts, direction),
	iAmInALift: ({ me, resa }) => direction => filterLifts(resa.lifts, direction).some(userIsInTheLift(me)),
	getPhotosArray: ({ resa }) => resa.chalet && resa.chalet.image_links.map(({ image_link = '', name = '' }) => ({ url: image_link, caption: name })),
	myLifts: ({ me, resa }) => resa.lifts.filter(userIsInTheLift(me)),
	myDepartureLift: (state, { myLifts }) => myLifts.find(l => l.direction === 'departure'),
	myReturnLift: (state, { myLifts }) => myLifts.find(l => l.direction === 'return'),
	menusOfDay: (state, { menus }) => day => menus.filter(menu => menu.day === day),
	menus: ({ resa }, { users }) => resa.menus.sort(sortByDatetime).map(addMenuDay).map(addUsersToMenu(users)),
	allMenuDays: (state, { menus }) => [...(new Set(menus.map(m => m.day)))],
	thumbnail: ({ image }) => filename => filename ? image.thumbnail + filename : '',
	myLiftDates: (state, { myLifts }) => myLifts.length === 2 ? myLifts.map(l => l.datetime).sort() : [],
}

const store = new Vuex.Store({ state, getters, actions, mutations })

store.subscribeAction({
	before: ({type, payload = {}}, state) => {
		console.warn(`[Start] dispatch ${type}`, payload)
		console.log('no loading ?', payload.noLoading)

		if(payload.noLoading === true) return
		state.loading++
	},
	// after not called on promise rejection...
	after: ({type, payload = {}}, state) => {
		console.warn(`[End] dispatch ${type}`, payload)
		console.log('no loading ?', payload.noLoading)
		if(payload.noLoading === true) return
		state.loading--
	}
})

export { store }

const {
	dispatch: vuexDispatch,
	commit: vuexCommit
} = store

export const dispatch = (fn, payload) => vuexDispatch('action', { fn, payload })
export const commit = mutation => payload => vuexCommit(mutation, payload)
