import mojs from '@mojs/core'

export const fireworks = (el, container) => ([
         // burst animation
         new mojs.Burst({
           parent:       el,
           count:        28,
           radius:       {0:30},
           children: {
             fill:       'green',
             opacity:    0.6,
             radius:     {'rand(5,20)':0},
             scale:      1,
             swirlSize:  15,
             duration:   1600,
             easing:     mojs.easing.bezier(0.1, 1, 0.3, 1),
             isSwirl:    true
           }
         }),
         // burst animation
         new mojs.Burst({
           parent:   el,
           count:    18,
           angle:    {0:20},
           radius:   {100:200},
           children: {
             fill:       'red',
             shape:      'line',
             opacity:    0.6,
             radius:     {'rand(5,20)':0},
             scale:      1,
             stroke:     'red',
             strokeWidth: 2,
             duration:   2500,
             delay:      300,
             easing:     mojs.easing.bezier(0.1, 1, 0.3, 1)
           }
         }),
         // burst animation
         new mojs.Burst({
           parent:   el,
           radius:   {40:80},
           count:    18,
           children: {
             fill:       'gold',
             opacity:    0.6,
             radius:     {'rand(5,20)':0},
             scale:      1,
             swirlSize:  15,
             duration:   2000,
             delay:      150,
             easing:     mojs.easing.bezier(0.1, 1, 0.3, 1),
             isSwirl:    true
           }
         }),
         // burst animation
         new mojs.Burst({
           parent:   el,
           count:    20,
           angle:    {0:-10},
           radius:   {10:80},
           children: {
             fill:       'red',
             opacity:    0.6,
             radius:     {'rand(10,20)':0},
             scale:      1,
             duration:   3000,
             delay:      650,
             easing:     mojs.easing.bezier(0.1, 1, 0.3, 1)
           }
         }),
         // icon scale animation
         new mojs.Tween({
           duration : 300,
           easing: mojs.easing.back.out,
           onUpdate: progress => {
             container.style.WebkitTransform = container.style.transform = 'scale3d(' + progress + ',' + progress + ',1)'
           }
         })
])

const translationCurve17 = mojs.easing.path('M0,100 C0,72 10,-0.1 50,0 C89.6,0.1 100,72 100,100')

export const licorneFx = (el, container) => ([
       // icon scale animation
       new mojs.Tween({
         duration : 1000,
         easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
         onUpdate: progress => {
            const translationProgress = translationCurve17(progress)
           container.style.WebkitTransform = container.style.transform = 'translate3d(' + -20 * translationProgress + '%,0,0)';  
         }
       }),
       // burst animation (line1)
       new mojs.Burst({
         parent: el,
         count:    5,
         radius:   {20:220},
         angle:    69,
         degree:   17,
         children: {
           shape:        'line',
           scale:        1,  
           radius:       {60:0},
           stroke:       ['#bf62a6', '#f28c33', '#f5d63d', '#79c267', '#78c5d6'],
           duration:     1400,
           easing:       mojs.easing.bezier(0.1, 1, 0.3, 1)
         },
       }),
       // burst animation (circles)
       new mojs.Burst({
         parent: el,
         count:    4,
         radius:   {20:50},
         degree:   20,
         angle:    70,
         opacity:  0.6,
         children: {
           fill:       ['#bf62a6','#f28c33','#f5d63d','#79c267','#78c5d6'],
           scale:      1,
           radius:     {'rand(5,20)':0},
           isSwirl:    true,
           swirlSize:  4,
           duration:   2800,
           delay:      [0,350,660,50,400],
           easing:     mojs.easing.bezier(0.1, 1, 0.3, 1)
         }
       }),
     ])