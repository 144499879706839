<template>
  <f7-page style="padding-top:5em;">
    <f7-list media-list>
      <f7-list-item
        v-for="({resa}) in resas"
        :key="resa.id"
        :title="resa.name"
        after
        subtitle
        text
        @click="fetchResa(resa.id)"
      >
        <img
          slot="media"
          :src="getImageSrc(resa)"
          width="80"
        />
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { state, dispatch, commit, actions } from "../store";

export default {
  props: {
    resas: Array
  },
  methods: {
    fetchResa(id) {
      this.$store.dispatch("fetchResa", { id });
    },
    getImageSrc: (resa)=> resa.chalet && resa.chalet.image_links[0].image_link +'?aki_policy=small'
  }
};
</script>

<style scoped>
.menus >>> .item-title {
  text-transform: capitalize;
}

iframe.googlemap {
  box-shadow: 0px 0px 20px 0px darkgrey;
  border-radius: 10px;
}
</style>