import moment from 'moment'
moment.locale('fr')

export const getHumanTime = time => {
	const momentTime = +moment(time, 'YYYY-MM-DD HH:mm:ss').format('HH')

	return momentTime <= 11 ? 'matin' 
			: momentTime <= 14 ? 'midi' 
				: momentTime <= 19 ? 'après-midi'
					: 'soir'
}

export const formatDate = datetime => datetime && moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('ddd DD MMM HH:mm')

export const formatDay = datetime => datetime && moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('ddd DD MMM')